import {
  Button,
  Checkbox,
  Flex,
  Layout,
  Modal,
  Popconfirm,
  Space,
  Spin,
  message,
} from "antd";
import React, { useCallback, useEffect, useRef, useState } from "react";
import icon_download from "../../../assets/historical/icon_download@2x.png";
import icon_fold from "../../../assets/historical/icon_fold@2x.png";
import icon_unfold from "../../../assets/historical/icon_unfold@2x.png";
import icon_delete from "../../../assets/historical/icon_close@2x.png";
import pay_sel from "../../../assets/historical/pay_icon_sel@2x.png";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import "./styles.css";
import {
  TaskItemData,
  apiCreatePaymentIntent,
  apiDdownloadPdf,
  apiDeletePdf,
  apiGetPreview,
  apiPaymentForPdf,
} from "../../../libs/http/api";
import icon_pdf from "../../../assets/historical/pdf@2x.png";
import { useRequest } from "ahooks";
import { pdfjs } from "react-pdf";
import { Document, Page } from "react-pdf";
import { t } from "../../../languages";
import "react-pdf/dist/Page/TextLayer.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { calculateLayoutWidth, isMobile, px2vw, mToPcPx } from "../../../libs/utils";
import styled from "styled-components";
import CheckoutForm from "../../Payment/CheckoutForm";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { useVoerkaI18n } from "@voerkai18n/react";
import useDetailPolling from "./DetailPolling";
import { logEvent } from "../../../libs/analytics";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const stripePromise = loadStripe(
  "pk_live_51O6o1BDkoOhDlqLb4znpIYocgnDFVqiQuACFD6hiCaxz4gWMJUEe7B2YqNuIOdgFwyMSdHOdMzyMBnHtfurjFZMO000tMUZTtc"
);

// const stripePromise = loadStripe(
//   "pk_test_51O6o1BDkoOhDlqLbrAg1wrG6nFiRGzJhJ3mtVMCj4ysCFVSYWqRILaqYbQf6gHc4XBzYVVIOUWnJ059E2VenehtU00u7eSumiE"
// );

// 状态枚举
export enum Status {
  UPLOADING = "UPLOADING", //上传中
  PENDING = "PENDING", // 上传完成准备转化
  PROCESSING = "PROCESSING", // 转化中
  COMPLETED = "COMPLETED", // 已完成
  FAILED = "FAILED", // 处理失败
  READY = "READY", // 准备上传
  LOADING = "LOADING", // 加载
}

let convert_type: Set<string> = new Set(); // 选择的转化数据

const options = {
  cMapUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/cmaps/`,
  standardFontDataUrl: "/standard_fonts/",
};

//选择框
interface CheckBoxProps {
  title: string; //选择框内容
  status: Status;
}

interface ItemProps {
  data: TaskItemData; // 任务数据
  onUpdateCompleted(): void; // 完成通知刷新列表
}

function useApi(item_status: Status, props: ItemProps) {
  const [clientSecret, setClientSecret] = useState("");

  const [isOpen, setIsOpen] = useState(false); // 是否展开
  const [downloadType, setDownloadType] = useState(1); // 当前选择

  // const upload = useRequest(apiUploadPdf, { manual: true }); // pdf上传
  // const { runAsync: runUpload } = upload;

  const preview = useRequest(apiGetPreview, { manual: true }); // 获取pdf预览图
  const { runAsync: getPreview } = preview;

  const paymentInfo = useRequest(apiPaymentForPdf, { manual: true }); // 获取付款信息
  const { runAsync: getPaymentInfo } = paymentInfo;

  const createIntent = useRequest(apiCreatePaymentIntent, { manual: true }); // 创建支付意图
  const { runAsync: create } = createIntent;

  const deletePdf = useRequest(apiDeletePdf, { manual: true }); // 删除PDF
  const { runAsync: runDelete } = deletePdf;

  const downloadpdf = useRequest(apiDdownloadPdf, { manual: true }); //下载PDF
  const { runAsync: download } = downloadpdf;

  //异步轮询 查询预览图是否制作完成，制作完成直接展示
  const previewAsync = useCallback(async () => {
    let intervalId: any;
    try {
      const response = await getPreview(props.data.pdf_id);
      if (response.data.code === 200) {
        props.data.preview_page_completed = true;
        console.log("获取图片成功");
        setIsOpen(true);
      }
    } catch (error) {
      console.error("请求出错：", error);
      intervalId = setTimeout(previewAsync, 3000);
    }

    return () => clearInterval(intervalId);
  }, [getPreview, props.data]);

  useEffect(() => {
    let isMounted = true;

    if (
      !props.data.preview_page_completed &&
      item_status === Status.PENDING &&
      isMounted
    ) {
      previewAsync();
    }

    return () => {
      isMounted = false;
    };
  }, [item_status]);

  return {
    isOpen,
    setIsOpen,
    downloadType,
    setDownloadType,
    preview,
    paymentInfo,
    getPaymentInfo,
    create,
    clientSecret,
    setClientSecret,
    runDelete,
    download,
    getPreview,
  };
}

export function CheckBoxItem(props: CheckBoxProps) {
  let item_status = props.status; // 当前Item状态

  return (
    <Checkbox
      onChange={(e: CheckboxChangeEvent) => {
        if (convert_type.has(props.title)) {
          convert_type.delete(props.title);
        } else {
          convert_type.add(props.title);
        }
        console.log(convert_type);
      }}
      disabled={
        !convert_type.has(props.title) &&
        (item_status === Status.PROCESSING || item_status === Status.COMPLETED)
      }
    >
      {props.title}
    </Checkbox>
  );
}

export default function HistoricalItem(props: ItemProps) {
  const { activeLanguage } = useVoerkaI18n();
  const [task_data, setTaskData] = useState(props.data);
  const [open, setOpen] = useState(false); //model框
  const [spinning, setSpinning] = React.useState<boolean>(false); // 全屏加载框
  const [clientSecret, setClientSecret] = useState("");
  const [item_status, setItemStatus] = useState<Status>(Status.LOADING);
  const api = useApi(item_status, props);
  const [isPro, setPro] = useState(false);
  const usePolling = useDetailPolling(
    props.data.pdf_id,
    (data: TaskItemData) => {
      setTaskData(data);
      setItemStatus(data.status as Status);
    }
  );

  useEffect(() => {
    setItemStatus(task_data.status as Status);
  }, [task_data]);

  useEffect(() => {
    if (item_status === Status.READY && props.data.file) {
      //task_data.status = Status.UPLOADING;
      // setItemStatus(Status.UPLOADING);
      // api
      //   .runUpload(props.data.file, (count) => {
      //     setProgress(count);
      //   })
      //   .then((resp) => {
      //     setProgress(100);
      //     // usePolling.runAsyncDetail();
      //     props.onUpdateCompleted();
      //   })
      //   .catch((e) => {
      //     console.log(e);
      //   });
    } else if (item_status === Status.PROCESSING) {
      usePolling.start();
    } else if (item_status === Status.COMPLETED) {
      usePolling.stop();
    }
  }, [item_status]);

  useEffect(() => {
    if (task_data.preview_page_completed) {
      api.getPreview(props.data.pdf_id).catch((error) => {});
    }
  }, []);

  //展开PDF信息
  const openClick = function () {
    api.setIsOpen(!api.isOpen);
  };

  //Pro点击
  const onProClick = async function (_isPro: boolean) {
    setPro(_isPro);
    clickPayemnt(_isPro);
    logEvent("Home", "pdf_pro_click", "pro");
  };

  //前往支付
  const clickPayemnt = (_isPro: boolean) => {
    logEvent("Home", "payment_info_click", "payment info");
    setSpinning(true);
    api
      .getPaymentInfo(
        task_data.pdf_id,
        activeLanguage ?? "en",
        _isPro ? _isPro : undefined
      )
      .then((resp) => {
        //toPayment(resp.data.data.payment_gateways,resp.data.data.pricing_code,props.data.pdf_id)
        toPayment(
          // ["alipay", "card", "wechat_pay"],
          resp.data.data.payment_gateways,
          resp.data.data.pricing_code,
          task_data.pdf_id
        );
      })
      .catch((error) => {
        setSpinning(false);
        if (error.response) {
          message.error(error.response.data.message);
        }
        //message.error(err)
      });
  };

  const toPayment = (
    payment_methods: Array<string>,
    pricing_code: string,
    pdf_id: number
  ) => {
    api
      .create({
        payment_methods,
        pdf_id,
        pricing_code,
      })
      .then((resp2) => {
        setClientSecret(resp2.data.data.client_secret);
        setSpinning(false);
        setOpen(true);
      })
      .catch((error) => {
        setSpinning(false);
        if (error.response) {
          message.error(error.response.data.message);
        }
      });
  };

  //删除PDF
  const deletePdf = () => {
    logEvent("Home", "delete_click", "delete");
    api
      .runDelete(task_data.pdf_id)
      .then((resp) => {
        message.success(resp.data.message);
        props.onUpdateCompleted();
      })
      .catch((err) => {});
  };

  //下载PDF
  const dowloadPdf = useCallback(() => {
    logEvent("Home", "download_click", "download");
    if (task_data.is_downloadable === false) {
      message.info(t("PDF正在制作中..."));
      return;
    }
    message.info(t("正在下载PDF中..."));
    api
      .download(task_data.pdf_id)
      .then((resp) => {
        handleDownload(resp.data.data.compiled_pdf, task_data.pdf_name);
      })
      .catch((err) => {});
  }, [task_data]);

  //触发下载事件
  function handleDownload(http_url: string, file_name: string) {
    // 发起文件下载请求
    fetch(http_url)
      .then((response) => response.blob())
      .then((blob) => {
        // 创建 Blob 对象后，使用 URL.createObjectURL() 方法生成下载链接
        const url = window.URL.createObjectURL(blob);

        // 创建一个隐藏的 <a> 标签来触发下载
        const a = document.createElement("a");
        a.href = url;
        a.download = file_name;
        a.style.display = "none";
        document.body.appendChild(a);
        a.click();
        // 释放 URL 对象
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => console.error("文件下载失败:", error));
  }

  return (
    <>
      <LayoutBox>
        <ItemMainStyle is_open={api.isOpen}>
          <ItemMainTopStyle>
            <TitleBox>
              <TitleImage src={icon_pdf} />
              <TitleStyle>{task_data.pdf_name}</TitleStyle>
            </TitleBox>
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              {(() => {
                switch (item_status) {
                  case Status.UPLOADING:
                    return (
                      <StatusTvStyle color="#F9A24E">
                        {t("正在上传")}
                      </StatusTvStyle>
                    );
                  case Status.PENDING:
                  case Status.PROCESSING:
                  case Status.COMPLETED:
                  case Status.FAILED:
                    return (
                      <Flex justify="center" align="center">
                        {/* {item_status === Status.READY ? "生成（多选）：" : ""}
                        <CheckBoxItem title="WORD" />
                        <CheckBoxItem title="HTML" />
                        <CheckBoxItem title="EPUB" />
                        <CheckBoxItem title="PDF" /> */}

                        {(() => {
                          if (item_status === Status.PENDING) {
                            return (
                              <StatusTvStyle color="#4E89F9">
                                {t("已上传")}
                              </StatusTvStyle>
                            );
                          } else if (item_status === Status.PROCESSING) {
                            return (
                              <StatusTvStyle color="#F9A24E">
                                {t("处理中")}
                              </StatusTvStyle>
                            );
                          } else if (item_status === Status.COMPLETED) {
                            return (
                              <StatusTvStyle color="#02BB00">
                                {t("已完成")}
                              </StatusTvStyle>
                            );
                          } else if (item_status === Status.FAILED) {
                            return (
                              <StatusTvStyle color="#FD4246">
                                {t("处理失败")}
                              </StatusTvStyle>
                            );
                          }
                        })()}
                      </Flex>
                    );
                }
              })()}
            </div>
          </ItemMainTopStyle>

          <ItemMainBottomStyle is_open={api.isOpen}>
            {isMobile() ? (
              <>
                <Flex style={{ width: "100%" }} justify="space-between">
                  {(() => {
                    switch (item_status) {
                      case Status.UPLOADING:
                        return (
                          <div
                            style={{
                              fontSize: "16px",
                              textAlign: "start",
                              fontWeight: 400,
                              color: "#222222",
                              width: "100%",
                              marginLeft: "24px",
                            }}
                          >
                            {t("文件正在上传中，请勿关闭窗口 ：")}
                            {props.data.progress}%
                          </div>
                        );
                      case Status.COMPLETED:
                        return (
                          <Flex
                            style={{ width: "100%" }}
                            align="center"
                            justify="space-between"
                          >
                            <ItemBottomSpace onClick={openClick}>
                              <img
                                alt=""
                                src={api.isOpen ? icon_fold : icon_unfold}
                              />
                              {api.isOpen === false ? t("展开") : t("收起")}
                            </ItemBottomSpace>
                            <ItemBottomSpace>
                              <img alt="" src={icon_download} />
                              {t("下载")}
                            </ItemBottomSpace>
                          </Flex>
                        );
                      case Status.PROCESSING:
                        return (
                          <div
                            style={{
                              fontSize: "16px",
                              fontWeight: 400,
                              color: "#222222",
                              marginLeft: "24px",
                            }}
                          >
                            {t("正在转换文件格式：")}
                            {task_data.progress}
                          </div>
                        );
                      case Status.PENDING:
                        return (
                          <Flex
                            style={{ width: "100%" }}
                            align="center"
                            justify="space-between"
                          >
                            <ItemBottomSpace onClick={openClick}>
                              <img
                                alt=""
                                src={api.isOpen ? icon_fold : icon_unfold}
                              />
                              {api.isOpen === false ? t("展开") : t("收起")}
                            </ItemBottomSpace>
                            <ItemBottomSpace>
                              <Button
                                onClick={() => clickPayemnt(false)}
                                style={{
                                  marginLeft: "16px",
                                  marginRight: "16px",
                                  borderRadius: "12px",
                                  width: "100%",
                                  fontSize: "16px",
                                  height: "45px",
                                  background: "#222222",
                                  fontWeight: "bold",
                                  color: "white",
                                }}
                              >
                                {t("开始转换")}
                              </Button>
                            </ItemBottomSpace>
                          </Flex>
                        );
                    }
                  })()}
                </Flex>
              </>
            ) : (
              <>
                {" "}
                {(() => {
                  switch (item_status) {
                    case Status.UPLOADING:
                      return (
                        <div
                          style={{
                            fontSize: px2vw(16),
                            fontWeight: 400,
                            color: "#222222",
                            width: "100%",
                            textAlign: "right",
                          }}
                        >
                          {t("文件正在上传中，请勿关闭窗口 ：")}
                          {props.data.progress}%
                        </div>
                      );
                    case Status.PENDING:
                      return (
                        <Space size="large">
                          {task_data.preview_page_completed === true ? (
                            <img
                              onClick={openClick}
                              style={{
                                width: px2vw(36),
                                height: px2vw(36),
                                cursor: "pointer",
                              }}
                              src={api.isOpen ? icon_fold : icon_unfold}
                              alt="open"
                            ></img>
                          ) : (
                            <Spin />
                          )}
                          <Button
                            onClick={() => clickPayemnt(false)}
                            style={{
                              fontSize: px2vw(16),
                              height: px2vw(50),
                              background: "#222222",
                              fontWeight: "bold",
                              color: "white",
                            }}
                          >
                            {t("开始转换")}
                          </Button>
                        </Space>
                      );
                    case Status.PROCESSING:
                      return (
                        <div
                          style={{
                            fontSize: px2vw(16),
                            fontWeight: 400,
                            color: "#222222",
                          }}
                        >
                          {t("正在转换文件格式：")}
                          {task_data.progress}
                        </div>
                      );
                    case Status.COMPLETED:
                      return (
                        <Space size="large">
                          <img
                            onClick={openClick}
                            style={{
                              width: px2vw(36),
                              height: px2vw(36),
                              cursor: "pointer",
                            }}
                            src={api.isOpen ? icon_fold : icon_unfold}
                            alt="open"
                          ></img>
                          <img
                            onClick={dowloadPdf}
                            style={{
                              width: px2vw(36),
                              height: px2vw(36),
                              cursor: "pointer",
                            }}
                            src={icon_download}
                            alt="download"
                          ></img>
                        </Space>
                      );
                    case Status.FAILED:
                      return (
                        <Popconfirm
                          title={t("是否删除该记录")}
                          description={t(
                            "记录删除后将无法恢复，是否确认删除？"
                          )}
                          onConfirm={deletePdf}
                          okText={t("确认")}
                          cancelText={t("取消")}
                        >
                          <Button
                            type="link"
                            danger
                            icon={
                              <img
                                style={{
                                  width: px2vw(36),
                                  height: px2vw(36),
                                  top: 0,
                                  right: px2vw(2),
                                  position: "absolute",
                                }}
                                src={icon_delete}
                                alt=""
                              />
                            }
                          ></Button>
                        </Popconfirm>
                      );
                  }
                })()}
              </>
            )}
          </ItemMainBottomStyle>
        </ItemMainStyle>
        {api.isOpen ? ( //展开后的样式
          <div className="expanded">
            <ExpandedBox>
              <Space
                size={0}
                style={{
                  width: "auto",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "relative", // 设置为相对定位
                  zIndex: 0, // 设置默认层级低于 PreviewMaskStyle
                }}
              >
                {api.preview.data?.data.data.preview_png.map((item) =>
                  item ? (
                    <PreviewItemStyle className="asd" key={item}>
                      {/* <Document
                        options={options}
                        file={item}
                        onLoadError={(error) => {
                          console.log(error);
                        }}
                      >
                        <Page
                          scale={window.innerWidth / 1920}
                          canvasBackground="#ffffff"
                          className="page_bg"
                          width={580}
                          pageNumber={1}
                        />
                      </Document> */}
                      <img style={{width: "100%"}} src={item} alt="" />
                    </PreviewItemStyle>
                  ) : (
                    <div></div>
                  )
                )}

                {api.preview.data?.data.data.preview_png.length === 0 ? (
                  <PreviewItemStyle />
                ) : (
                  <div />
                )}
              </Space>
              <PreviewMaskStyle>
                {item_status === Status.PENDING ? (
                  <ConvertTv>
                    {t("内容仅供预览效果，点击")}
                    <a onClick={() => clickPayemnt(true)}>
                      {t("【开始转换】")}
                    </a>
                    {t("处理全部页面")}
                  </ConvertTv>
                ) : (
                  <div>
                    <ConvertTvPreview>
                      {t("内容仅供预览效果，下载后为完整文件")}
                    </ConvertTvPreview>
                    <DownloadButton onClick={dowloadPdf}>
                      {t("下载")}
                    </DownloadButton>
                  </div>
                )}
                <ConvertTvGrey>
                  {t(
                    "出于隐私保护，我们将最多为您保留30天内的文件"
                  )}
                  <br/>
                  {t(
                    "之后会进行删除，请尽快保存"
                  )}
                </ConvertTvGrey>
              </PreviewMaskStyle>
            </ExpandedBox>
          </div>
        ) : (
          <div />
        )}
      </LayoutBox>
      <Modal
        width={600}
        title={t("支付下载")}
        style={{
          fontSize: 28,
        }}
        centered
        open={open}
        footer={null}
        onOk={() => setOpen(false)}
        onCancel={() => {
          setPro(true);
          setOpen(false);
          logEvent("Home", "payment_cancel", "Cancel");
        }}
      >
        <center>
          <Space size={30}>
            <ModalTopButton onClick={() => onProClick(false)} isPro={!isPro}>
              <TBContent>
                <TopButtonLabel>OCR</TopButtonLabel>
                <Flex justify="center" align="center" vertical>
                  <TopButtonLabelRight>99%</TopButtonLabelRight>
                  <TopButtonLabelRB>{t("识别正确率")}</TopButtonLabelRB>
                </Flex>
              </TBContent>
              {!isPro && <img className="sel" src={pay_sel} alt="" />}
            </ModalTopButton>
            <ModalTopButton onClick={() => onProClick(true)} isPro={isPro}>
              <TopIcon>{t("推荐")}</TopIcon>
              <TBContent>
                <TopButtonLabel>OCR Pro</TopButtonLabel>
                <Flex justify="center" align="center" vertical>
                  <TopButtonLabelRight>99.5%</TopButtonLabelRight>
                  <TopButtonLabelRB>{t("识别正确率")}</TopButtonLabelRB>
                </Flex>
              </TBContent>
              {isPro && <img className="sel" src={pay_sel} alt="" />}
            </ModalTopButton>
          </Space>
        </center>
        {clientSecret ? (
          <center>
            <Elements
              options={{
                clientSecret: clientSecret,
                appearance: { theme: "stripe" },
              }}
              stripe={stripePromise}
            >
              <CheckoutForm
                update={() => {
                  usePolling.start();
                  setOpen(false);
                }}
                pdf_id={task_data.pdf_id}
                data={api.paymentInfo.data?.data.data}
              />
            </Elements>
          </center>
        ) : (
          <div></div>
        )}
      </Modal>
      <Spin style={{ zIndex: 9999 }} spinning={spinning} fullscreen />
    </>
  );
}

const LayoutBox = styled.div`
  width: 100%;
  height: auto;
`;

const TitleImage = styled.img`
  width: 32px;
  height: 32px;

  @media only screen and (max-width: 768px) {
    width: 123px;
    height: 123px;
  }
`;

const ExpandedBox = styled.div`
  position: relative;
  margin-top: 50px;
  margin-bottom: 50px;
  @media only screen and (max-width: 768px) {
    margin-top: 0px;
    margin-bottom: 80px;
  }
`;

const TitleBox = styled.div`
  margin-left: 30px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 768px) {
    width: 100%;
    justify-content: start;
    margin-left: 80px;
  }
`;

const TitleStyle = styled.div`
  max-width: 800px;
  font-weight: 400;
  font-size: 18px;
  color: #222222;
  margin-left: 10px;
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  font-family: Montserrat Bold;
  text-overflow: ellipsis;

  @media only screen and (max-width: 768px) {
    font-size: 72px;
    line-height: 92px;
    margin-left: 41px;
  }
`;

interface StatusTvStyleProps {
  color: string;
}

const StatusTvStyle = styled.div<StatusTvStyleProps>`
  display: flex;
  align-items: center;
  color: ${(props) => props.color};
  padding-left: 6px;
  padding-right: 6px;
  height: 32px;
  border-radius: 6px;
  font-size: 16px;
  border: 2px solid ${(props) => props.color};

  @media only screen and (max-width: 768px) {
    border-radius: 31px;
    border: 5px solid ${(props) => props.color};
    margin-right: 80px;

    font-size: 51px;
    line-height: 77px;
    height: 123px;
    padding: 26px;
  }
`;

const ConvertTv = styled.div`
  font-family: SourceHanSansCN, SourceHanSansCN;
  font-weight: bold;
  font-size: 20px;
  color: #222222;
  line-height: 29px;
  text-align: left;
  font-style: normal;

  a {
    color: #f9a24e;
    cursor: pointer;
  }
  @media only screen and (max-width: 768px) {
    font-size: 61px;
    line-height: 77px;
  }
`;

const ConvertTvGrey = styled.div`
  font-family: Montserrat;
  font-weight: 400;
  font-size: 16px;
  color: #999999;
  margin-top: 32px;
  text-align: center;
  a {
    color: #f9a24e;
    cursor: pointer;
  }
  @media only screen and (max-width: 768px) {
    font-size: 46px;
    line-height: 56px;
    margin-top: 64px;
  }
`;

const ConvertTvPreview = styled.div`
  font-family: Montserrat;
  font-size: 20px;
  color: #222222;
  margin-top: 130px;
  font-weight: bold;
  a {
    color: #f9a24e;
    cursor: pointer;
  }
  @media only screen and (max-width: 768px) {
    font-size: 52px;
    line-height: 62px;
    margin-top: 240px;
  }
`;

const ItemMainStyle = styled.div<{ is_open: boolean }>`
  width: 100%;
  display: flex;
  background: white;
  justify-content: end;
  align-items: center;
  border-radius: ${(props) => (props.is_open ? "24px 24px 0 0" : "24px")};

  height: 100px;

  /* 上边框 */
  border-top-style: solid; /* 上边框样式 */
  border-top-width: 1px; /* 上边框宽度 */
  border-top-color: #ebebeb; /* 上边框颜色 */

  /* 右边框 */
  border-right-style: solid; /* 右边框样式 */
  border-right-width: 1px; /* 右边框宽度 */
  border-right-color: #ebebeb; /* 右边框颜色 */

  /* 左边框 */
  border-left-style: solid; /* 左边框样式 */
  border-left-width: 1px; /* 左边框宽度 */
  border-left-color: #ebebeb; /* 左边框颜色 */

  @media only screen and (max-width: 768px) {
    height: 522px;
    flex-direction: column;
    justify-content: space-between;
  }
`;

const ItemMainTopStyle = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

const ItemMainBottomStyle = styled.div<{ is_open: boolean }>`
  display: flex;
  flex-direction: row;

  margin-right: 30px;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: end;

  /* 下边框 */
  border-bottom-style: solid; /* 下边框样式 */
  border-bottom-width: 1px; /* 下边框宽度 */
  border-bottom-color: #ebebeb; /* 下边框颜色 */

  @media only screen and (max-width: 768px) {
    background: #fdfbf5;
    justify-content: space-between;
    margin-right: 0px;
    border-radius: ${(props) => (props.is_open ? "0" : "0 0 24px 24px")};
    /* 下边框 */
    border-bottom-style: solid; /* 下边框样式 */
    border-bottom-width: ${(props) => (props.is_open ? "0" : "1px")};
    border-bottom-color: #ebebeb; /* 下边框颜色 */
  }
`;

const PreviewItemStyle = styled.div`
  width: 595px;
  height: 842px;
  background: white;
  border: 1px solid #ebebeb;
  animation: hide-item 2s ease-in;
  overflow: hidden;

  @media only screen and (max-width: 768px) {
    width: 827px;
    height: 1172px;
  }
`;

const PreviewMaskStyle = styled.div`
  width: 1190px;
  height: 422px;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.8) 17%,
    rgba(255, 255, 255, 0.96) 40%,
    #ffffff 100%
  );
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 999; // 设置层级高于 Flex
  display: flex;
  align-items: center;
  padding-bottom: 40px;

  flex-direction: column;
  justify-content: end;

  @media only screen and (max-width: 768px) {
    width: 1654px;
    height: 614px;
  }
`;

const DownloadButton = styled.button`
  cursor: pointer;
  margin-top: 24px;
  background: #fdc448;
  width: 300px;
  height: 60px;
  font-size: 24px;
  font-weight: bold;
  box-shadow: 6px 6px 0px 0px #373737;
  border-radius: 8px;
  border: none;
  margin-bottom: 16px;

  :hover {
    color: #ffffff;
  }
`;

const ModalTopButton = styled.button<{ isPro: boolean }>`
  position: relative;
  width: 255px;
  height: 70px;
  background: #ffffff;
  border-radius: 8px;
  border: 3px solid ${({ isPro }) => (isPro ? "#222222" : "#EBEBEB")};
  margin-top: 12px;
  margin-bottom: 12px;

  color: ${({ isPro }) => (isPro ? "#222222" : "#999999")};

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  position: relative;

  * {
    color: inherit;
  }

  @media only screen and (max-width: 768px) {
    width: 768px;
    height: 205px;
    border-radius: 20px;
  }
  .sel {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 38px;
    height: 38px;
    @media only screen and (max-width: 768px) {
      width: ${mToPcPx(24)};
      height: ${mToPcPx(24)};
    }
  }
`;

const TBContent = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
`;

const TopButtonLabel = styled.label`
  width: auto;
  font-weight: bold;
  font-size: 22px;
  line-height: 64px;
  text-align: center;
  font-style: normal;
  margin-right: 16px;

  @media only screen and (max-width: 768px) {
    font-size: 62px;
    line-height: 205px;
  }
`;

const TopButtonLabelRight = styled.label`
  font-weight: bold;
  font-size: 18px;
  line-height: 26px;
  text-align: center;
  font-style: normal;

  @media only screen and (max-width: 768px) {
    font-size: 62px;
    line-height: 92px;
  }
`;

const TopButtonLabelRB = styled.label`
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  font-style: normal;

  @media only screen and (max-width: 768px) {
    font-size: 51px;
    line-height: 92px;
  }
`;

const TopIcon = styled.label`
  position: absolute;
  left: -3px;
  top: -16px;
  padding: 0px 5px 0px;
  height: 32px;
  background: linear-gradient(180deg, #f49f9f 0%, #f53e3e 100%);
  border-radius: 24px 100px 100px 0px;

  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
  line-height: 30px;
  text-align: center;
  font-style: normal;

   @media only screen and (max-width: 768px) {
    height: 82px;
    font-size: 46px;
    line-height: 82px;
    padding: 0px 25px 0px;
     top: -36px;
   }
`;

const ItemBottomSpace = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  justify-content: center;
  img {
    width: 92px;
    height: 92px;
  }

  font-family: Montserrat;
  font-weight: 400;
  font-size: 72px;
  color: #222222;
  line-height: 92px;
  text-align: center;
  font-style: normal;
`;
