export default {
    "支持格式：PDF": 1,
    "精准度": 2,
    "为什么选择我们": 3,
    "读者": 4,
    "清晰愉悦的阅读体验": 5,
    "大语言模型": 6,
    "格式就是注意力": 7,
    "创作者": 8,
    "复制粘贴的快感": 9,
    "我们想说": 10,
    "更多": 11,
    "上传文件": 12,
    "处理记录": 13,
    "上传失败！": 14,
    "下载文件共需页数": 15,
    "输入邀请码": 16,
    "邀请码抵扣": 17,
    "每个邀请码可以抵扣500页内的文件": 18,
    "请输入邀请码": 19,
    "抵扣": 20,
    "正在上传PDF文件...": 21,
    "请上传正确的PDF文件！": 22,
    "PDF正在制作中...": 23,
    "正在下载PDF中...": 24,
    "正在上传": 25,
    "已上传": 26,
    "处理中": 27,
    "已完成": 28,
    "处理失败": 29,
    "文件正在上传中，请勿关闭窗口 ：": 30,
    "开始转换": 31,
    "正在转换文件格式：": 32,
    "是否删除该记录": 33,
    "记录删除后将无法恢复，是否确认删除？": 34,
    "确认": 35,
    "取消": 36,
    "内容仅供预览效果，点击": 37,
    "【开始转换】": 38,
    "处理全部页面": 39,
    "内容仅供预览效果，下载后为完整文件": 40,
    "下载": 41,
    "支付下载": 42,
    "文件上传完成后可以确认生成格式，您可以同时上传更多文件": 43,
    "支付": 44,
    "登录": 45,
    "谷歌一键登录": 46,
    "正在登录": 47,
    "分享链接给新朋友注册，双方将获得100页Pro转换": 48,
    "复制专属链接": 49,
    "图片分享": 50,
    "请先登录！": 51,
    "扫描二维码注册": 52,
    "获得100页PDF Pro处理次数": 53,
    "可处理页书：": 54,
    "退出账号": 55,
    "复制成功！": 56,
    "登录已过期，请重新登录！": 57,
    "保存至本地": 58,
    "优先抵扣页数：": 59,
    "推荐": 60,
    "识别正确率": 61,
    "填写邀请码": 62,
    "你将获得100页PDF PRO处理次数": 63,
    "提交": 64,
    "高精OCR": 65,
    "保留文件原排版": 66,
    "文件压缩": 67,
    "我们是为节省成本而来的": 68,
    "减少90%无效时间": 69,
    "不必再人工逐字逐句输入、校对、排版": 70,
    "高达99.5%的识别精确度让扫描件即刻能用": 71,
    "图片转文字？不够！": 72,
    "还要保留格式！我们只做最佳转换器！": 73,
    "从此告别转文字后比对着原稿一行一行辛苦排版工作": 74,
    "实验数据：PDF Pro服务将通过 LLM 纠错，将文本识别精度提升至99.5%": 75,
    "节省95%存储成本": 76,
    "图片变成文字": 77,
    "立刻节省95%存储空间": 78,
    "让钱花去刀刃上，我们来解决问题": 79,
    "实验数据：90MB文件处理后缩小至6MB，在高清化的同时有效释放接近94%的空间": 80,
    "定价": 81,
    "99.5%识别正确率": 82,
    "S$0.07/页": 83,
    "适合专业人士/企业/机构": 84,
    "尽可能高性价比 尽可能高效": 85,
    "让大语言模为您智能纠错": 86,
    "99%识别正确率": 87,
    "S$0.05/页": 88,
    "适合对精确度要求不高的个人": 89,
    "尽可能低廉": 90,
    "满足日常需求": 91,
    "图书转文字": 92,
    "减少95%存储成本": 93,
    "通过图片转文字进行存储": 94,
    "90MB轻松转为6MB": 95,
    "瘦身文件简简单单": 96,
    "你可以存更多": 97,
    "存储更多，看见更多": 98,
    "LLMs企业/学术研究机构": 99,
    "增强你的数据集": 100,
    "比竞争对手更完备": 101,
    "扫描件也可以成为模型可阅读的数据集": 102,
    "古早文档统统收入囊中": 103,
    "有效=高效": 104,
    "只做有效的事": 105,
    "节省时间、财力": 106,
    "把耗时耗力的重复性工作安心交给我们处理": 107,
    "电子书企业": 108,
    "降低95%成本": 109,
    "快速促进书籍、档案数字化": 110,
    "节省存储、传输": 111,
    "更好服务用户": 112,
    "给用户最快速提供最高清的图书": 113,
    "节省存储成本": 114,
    "小小的移动设备容纳更多书籍": 115,
    "再也不必担心存储空间": 116,
    "对自己更好": 117,
    "清晰的阅读，美好的体验": 118,
    "学生/老师": 119,
    "课件/文献语言障碍不复存在": 120,
    "扫描件变文本件": 121,
    "直接翻译就好啦": 122,
    "DDL杀手": 123,
    "快速复制引用，论文无忧": 124,
    "残障人士": 125,
    "让所有文件信息都能被读取": 126,
    "扫描件转换文本后即可成为能够被语音阅读的文件": 127,
    "让所有文件变成无障碍": 128,
    "关心在身边的他/她们": 129,
    "如果你希望为无障碍文件作出贡献": 130,
    "非常欢迎联系我们": 131,
    "我们将收录您的书籍，用于公益事业": 132,
    "语言学习者": 133,
    "快速提升语言能力": 134,
    "多种语言自由切换阅读，不必再浪费过多时间查阅": 135,
    "你可以学习更多": 136,
    "看见更多语种，看见更多可能": 137,
    "用户反馈": 138,
    "王先生": 139,
    "从事LLM相关领域研发": 140,
    "非常好，推荐！": 141,
    "很不错，有了这个工具再也不用带着纸质资料外出了。": 142,
    "文本提取准确度很高，大多数类型的扫描件转换完都没什么误差。": 143,
    "转换完体验太棒了！终于可以翻译成多种语言！阅读毫无障碍！": 144,
    "看了别人推荐过来的，很好用。": 145,
    "PDF到PDF的转化": 146,
    "图片转文字": 147,
    "使用场景": 148,
    "常见问题": 149,
    "博客": 150,
    "在线客服": 151,
    "Q&A": 152,
    "Blog": 153,
    "阅读爱好者": 154,
    "分享更多Pdf to Pdf相关内容": 155,
    "分享更多Pdf to Pdf相关内容、ocr文本提取技术、文件瘦身": 156,
    "常见问题，图片OCR文本识别FAQ": 157,
    "在这里，我们列出了一些关于pdf到pdf转换的常见问题。如果您没有找到所需的信息，请随时通过pdftopdf@leqi.ai与我们联系。": 158,
    "免费在线OCR—将PDF扫描件转换为文本": 159,
    "通过ocr技术，将pdf扫描件转换为pdf文本文件，一键实现文件瘦身的同时保留文件原排版。阅读体验更佳，内容复制、搜索无阻碍!": 160,
    "上传文件，通过OCR技术快速识别pdf扫描件内容，提取结果精准度高达99%＋。PDF扫描件内容提取后，点击下载即可获得pdf文本格式文件。": 161,
    "Pdf to Pdf| 高效工具全方位定价方案 | 量身定制满足您的需求": 162,
    "探索Pdf to Pdf软件的灵活定价计划，体验无与伦比的功能，享受个性化服务，现在注册更有超值优惠！": 163,
    "更多Pdf文档ocr文本提取处理记录": 164,
    "点击查看历史处理记录。": 165,
    "验证码错误！": 166,
    "邮箱登录": 167,
    "请输入邮箱": 168,
    "请输入密码": 169,
    "验证码已发送至邮箱": 170,
    "请输入验证码": 171,
    "设置密码": 172,
    "从事商务工作": 173,
    "刘先生": 174,
    "从事教师工作": 175,
    "赵先生": 176,
    "陈先生": 177,
    "个人财务管理者": 178,
    "张女士": 179,
    "展开": 180,
    "可处理页书： ": 181,
    "语言": 182,
    "PDF格式转换工具主要特点": 183,
    "文字识别准确度高": 184,
    "高精度OCR文字识别技术，": 185,
    "高达99.5%": 186,
    "的识别精确度让扫描件即刻能用，": 187,
    "减少90%": 188,
    "无效时间。目前可识别中文简体、英文，后续还会新增日语、德语等其他语言。": 189,
    "批量处理高效快捷": 190,
    "支持一次性处理多张PDF扫描件，": 191,
    "在线批量": 192,
    "文字识别，一键批量格式转换，极大提高效率。": 193,
    "机器学习识别，准确度随着自动学习越来越高，识别速度快；识别经过加密处理，无需担心隐私。": 194,
    "图片转文字？不够！还要": 195,
    "保留格式": 196,
    "！PDF在线格式转换工具让你从繁琐的逐字输入文本的工作中解放，同时摆脱人工比对原稿逐字校对、排版。": 197,
    "压缩PDF扫描件": 198,
    "图片变文字": 199,
    "，立刻节省95%存储空间，经测试90MB文件处理后可缩小至6MB，在高清化的同时有效释放接近94%的空间，把钱花在刀刃上，PDFtoPDF": 200,
    "在线工具": 201,
    "高效为您解决问题！": 202,
    "为出行减负": 203,
    "摆脱纸质资料，随时随地打开手机即可阅读，给读者带来清晰愉悦的阅读体验。从PDF扫描件中提取文本，创建": 204,
    "可搜索、可复制和可访问": 205,
    "的内容，给创作者带来复制粘贴的快感！": 206,
    "OCR自动识别PDF扫描件，转换结果高清": 207,
    "只需将PDF扫描件上传，等待几秒，OCR将自动扫描并提取扫描件中的文字，一比一转换为更清晰的PDF文本文件，从而创建便于搜索、编辑和访问的内容。": 208,
    "支持移动端 & PC端": 209,
    "无论是手机上还是电脑上，都可以使用，直接通过网址进入，无需下载，方便快捷、不占内存！再也不用担心遇到临时有文件需要处理的情况啦，直接在手机移动端完成在线OCR识别，随时随地高效完成！": 210,
    "学生与老师": 211,
    "学生可以使用OCR技术将教材、笔记或研究资料中的文字快速提取出来，便于查找、整理学习材料或制作电子笔记。教师可以扫描讲义、参考书籍，通过OCR转换成文本，用于制作PPT、在线课程资料等。": 212,
    "做出海业务的企业": 213,
    "企业出海业务合同扫描件经OCR技术，一键转换为PDF文本文件，提升信息录入速度与准确性，便于后期翻译，让您快速了解合同内容，支持搜索查找重要条款，增强跨国合同管理的效率与便利性。": 214,
    "电子书企业使用OCR工具从扫描的文档、书籍、杂志等纸质材料中提取文字，快速实现纸质内容数字化，提升扫描件内容的可访问性、增强用户体验。": 215,
    "经常需要外出或习惯于用电子设备阅读的朋友，使用OCR在线工具将准备看的书扫描为电子文档，随时随地皆可阅读。还可以根据个人喜好调整电子文本布局、字体和颜色，营造个性化阅读环境。更重要的是彻底让我们摆脱传统厚重的纸质书本，最大程度节省空间、减轻负重。": 216,
    "OCR工具为语言学习者开启了一扇通往更广阔学习资源的大门，不仅能够将纸质材料瞬间转化为可编辑的电子文本，便于翻译、听写练习及个性化学习资料的创建，还可促进跨文化交流和学术研究的深化。": 217,
    "OCR技术为残障人士，尤其是视觉障碍者和阅读困难者，架起了通往信息自由和平等获取的桥梁。它将纸质材料瞬间转化为可听、可视的电子格式，满足多样化阅读需求，助力残障人士自主学习、高效沟通，极大地提升了他们在教育、工作和社会生活中的参与度与独立性。通过OCR，我们共同构建了一个更加包容和无障碍的世界。": 218,
    "如何将PDF扫描件转换为PDF文本？": 219,
    "上传PDF扫描件": 220,
    "OCR识别和提取": 221,
    "通过OCR技术": 222,
    "快速": 223,
    "识别pdf扫描件内容，提取结果精准度高达": 224,
    "99%＋": 225,
    "PDF扫描件内容提取后，点击下载即可获得pdf文本格式文件，便于查找内容、节省存储空间、阅读体验更佳": 226,
    "PDF扫描件内容提取后，点击下载即可获得pdf文本格式文件，便于查找内容、": 227,
    "节省存储空间、阅读体验更佳": 228,
    "邮箱：": 229,
    "立即转换 pdf": 230,
    "PDF扫描件文字提取利器，一键提升企业与学术文献处理效率": 231,
    "PDFtoPDF文字提取利器，可用于快速摘录文献、报纸、杂志上的重要信息，进行引用或创作，提高研究和写作效率。支持将手稿或旧文档数字化，方便存储和检索，是LLMs企业和学术研究机构的得力助手！": 232,
    "高效提取PDF扫描件文本——电子书企业必备工具": 233,
    "利用先进的OCR技术，PDFtoPDF网站能精准提取PDF扫描件中的文字内容，助力电子书企业快速将纸质书籍、文档转变为可编辑的文本，实现数字化飞跃。简化编辑流程，提升出版效率，为您的电子书项目注入无限创意与灵活性。立即体验，开启智慧出版新时代。": 234,
    "PDF扫描件图片文本一键提取：从图片到清晰文字，升级阅读爱好者体验": 235,
    "专为阅读爱好者设计，这款OCR工具能巧妙地从PDF扫描件中抓取图片内的文字，将其转化为清晰、易读的文本格式。从此，不再受限于扫描文档的阅读不便，享受如纸质书般的流畅与清晰，让每一次阅读都成为一种享受。提升文本质量，尽享无界阅读新乐趣，现在就加入我们，开启您的舒适阅读之旅。": 236,
    "教育助力：PDF扫描件文本轻松提取，优化学习资料，提升教学体验": 237,
    "专为学生和教师打造的OCR解决方案，高效从PDF扫描件及图片中精准提取文本，转化成清晰可编辑的内容，让繁琐的学习资料瞬间变得井井有条。无论是论文研读、讲义整理还是教材制作，都能显著提升效率，助力学术研究与课堂教学迈上新台阶。立即体验，开启智慧学习新篇章。": 238,
    "OCR文字识别技术：将PDF扫描件转化为语音可读文本，构筑无障碍信息桥梁": 239,
    "利用先进的Text Recognition OCR技术，将PDF扫描文档轻松转换成可编辑、可语音阅读的文本，打破了残障人士阅读障碍。无论是学习资料、工作文件还是日常资讯，都能瞬间转化，让信息获取不再受限，促进数字内容的全面无障碍。": 240,
    "OCR技术赋能语言学习：PDF扫描件秒变文本，多语言翻译随心阅": 241,
    "OCR文本提取 & 翻译神器：一键将PDF扫描文档转换成可编辑文本，无论是外语教材还是原版文献，都能即扫即译。支持多种语言切换，大大节省查阅时间，多语言阅读无障碍，学习加速新体验！": 242,
    "上传PDF扫描件，轻松转换文本——高效PDF转文字工具": 243,
    "端点": 244,
    "认证": 245,
    "需要'web'类型的JWT Token": 246,
    "请求头": 247,
    "响应": 248,
    "成功": 249,
    "失败": 250,
    "Python代码示例": 251,
    "使用示例": 252,
    "参数": 253,
    "需要'test_company'类型的JWT Token": 254,
    "PDF文件，作为multipart/form-data上传": 255,
    "错误信息，例如：No file part in the request": 256,
    "PDF处理任务的ID": 257,
    "API": 258,
    "正式密钥": 259,
    "测试环境": 260,
    "接入文档": 261,
    "申请发票": 262,
    "获取密钥": 263,
    "密钥:": 264,
    "复制": 265,
    "充值": 266,
    "剩余：": 267,
    "页": 268,
    "登录获取密钥": 269,
    "可免费测试3次PDF文件": 270,
    "1. 获取客户端密钥": 271,
    "复制代码": 272,
    "2. 获取访问令牌": 273,
    "3. 上传PDF文件": 274,
    "4. 获取PDF处理状态": 275,
    "5. 下载PDF文件": 276,
    "企业抬头:": 277,
    "请输入": 278,
    "税号:": 279,
    "开户行:": 280,
    "电话:": 281,
    "地址:": 282,
    "申请": 283,
    "10000页": 284,
    "50000页": 285,
    "复制成功": 286,
    "请确保将 `<YOUR_WEB_JWT_TOKEN>`, `<YOUR_TEST_COMPANY_JWT_TOKEN>`,": 287,
    "`<YOUR_CLIENT_SECRET>`, `<YOUR_TASK_ID>`, 和 `path/to/your/file.pdf` 替换为合适的值，以进行测试。": 288,
    "请完善所有信息": 289,
    "提交成功": 290,
    "感谢您的提交，我们将在10个工作日内处理您的开票请求，如有疑问或其他要求请联系我们的邮箱：pdftopdf@leqi.ai": 291,
    "收起": 292,
    "出于隐私保护，我们将最多为您保留30天内的文件": 293,
    "之后会进行删除，请尽快保存": 294
}