export default {
    "1": "支持格式：PDF",
    "2": "精准度",
    "3": "为什么选择我们",
    "4": "读者",
    "5": "清晰愉悦的阅读体验",
    "6": "大语言模型",
    "7": "格式就是注意力",
    "8": "创作者",
    "9": "复制粘贴的快感",
    "10": "我们想说",
    "11": "更多",
    "12": "上传文件",
    "13": "处理记录",
    "14": "上传失败！",
    "15": "下载文件共需页数",
    "16": "输入邀请码",
    "17": "邀请码抵扣",
    "18": "每个邀请码可以抵扣500页内的文件",
    "19": "请输入邀请码",
    "20": "抵扣",
    "21": "正在上传PDF文件...",
    "22": "请上传正确的PDF文件！",
    "23": "PDF正在制作中...",
    "24": "正在下载PDF中...",
    "25": "正在上传",
    "26": "已上传",
    "27": "处理中",
    "28": "已完成",
    "29": "处理失败",
    "30": "文件正在上传中，请勿关闭窗口 ：",
    "31": "开始转换",
    "32": "正在转换文件格式：",
    "33": "是否删除该记录",
    "34": "记录删除后将无法恢复，是否确认删除？",
    "35": "确认",
    "36": "取消",
    "37": "内容仅供预览效果，点击",
    "38": "【开始转换】",
    "39": "处理全部页面",
    "40": "内容仅供预览效果，下载后为完整文件",
    "41": "下载",
    "42": "支付下载",
    "43": "文件上传完成后可以确认生成格式，您可以同时上传更多文件",
    "44": "支付",
    "45": "登录",
    "46": "谷歌一键登录",
    "47": "正在登录",
    "48": "分享链接给新朋友注册，双方将获得100页Pro转换",
    "49": "复制专属链接",
    "50": "图片分享",
    "51": "请先登录！",
    "52": "扫描二维码注册",
    "53": "获得100页PDF Pro处理次数",
    "54": "可处理页书：",
    "55": "退出账号",
    "56": "复制成功！",
    "57": "登录已过期，请重新登录！",
    "58": "保存至本地",
    "59": "优先抵扣页数：",
    "60": "推荐",
    "61": "识别正确率",
    "62": "填写邀请码",
    "63": "你将获得100页PDF PRO处理次数",
    "64": "提交",
    "65": "高精OCR",
    "66": "保留文件原排版",
    "67": "文件压缩",
    "68": "我们是为节省成本而来的",
    "69": "减少90%无效时间",
    "70": "不必再人工逐字逐句输入、校对、排版",
    "71": "高达99.5%的识别精确度让扫描件即刻能用",
    "72": "图片转文字？不够！",
    "73": "还要保留格式！我们只做最佳转换器！",
    "74": "从此告别转文字后比对着原稿一行一行辛苦排版工作",
    "75": "实验数据：PDF Pro服务将通过 LLM 纠错，将文本识别精度提升至99.5%",
    "76": "节省95%存储成本",
    "77": "图片变成文字",
    "78": "立刻节省95%存储空间",
    "79": "让钱花去刀刃上，我们来解决问题",
    "80": "实验数据：90MB文件处理后缩小至6MB，在高清化的同时有效释放接近94%的空间",
    "81": "定价",
    "82": "99.5%识别正确率",
    "83": "S$0.07/页",
    "84": "适合专业人士/企业/机构",
    "85": "尽可能高性价比 尽可能高效",
    "86": "让大语言模为您智能纠错",
    "87": "99%识别正确率",
    "88": "S$0.05/页",
    "89": "适合对精确度要求不高的个人",
    "90": "尽可能低廉",
    "91": "满足日常需求",
    "92": "图书转文字",
    "93": "减少95%存储成本",
    "94": "通过图片转文字进行存储",
    "95": "90MB轻松转为6MB",
    "96": "瘦身文件简简单单",
    "97": "你可以存更多",
    "98": "存储更多，看见更多",
    "99": "LLMs企业/学术研究机构",
    "100": "增强你的数据集",
    "101": "比竞争对手更完备",
    "102": "扫描件也可以成为模型可阅读的数据集",
    "103": "古早文档统统收入囊中",
    "104": "有效=高效",
    "105": "只做有效的事",
    "106": "节省时间、财力",
    "107": "把耗时耗力的重复性工作安心交给我们处理",
    "108": "电子书企业",
    "109": "降低95%成本",
    "110": "快速促进书籍、档案数字化",
    "111": "节省存储、传输",
    "112": "更好服务用户",
    "113": "给用户最快速提供最高清的图书",
    "114": "节省存储成本",
    "115": "小小的移动设备容纳更多书籍",
    "116": "再也不必担心存储空间",
    "117": "对自己更好",
    "118": "清晰的阅读，美好的体验",
    "119": "学生/老师",
    "120": "课件/文献语言障碍不复存在",
    "121": "扫描件变文本件",
    "122": "直接翻译就好啦",
    "123": "DDL杀手",
    "124": "快速复制引用，论文无忧",
    "125": "残障人士",
    "126": "让所有文件信息都能被读取",
    "127": "扫描件转换文本后即可成为能够被语音阅读的文件",
    "128": "让所有文件变成无障碍",
    "129": "关心在身边的他/她们",
    "130": "如果你希望为无障碍文件作出贡献",
    "131": "非常欢迎联系我们",
    "132": "我们将收录您的书籍，用于公益事业",
    "133": "语言学习者",
    "134": "快速提升语言能力",
    "135": "多种语言自由切换阅读，不必再浪费过多时间查阅",
    "136": "你可以学习更多",
    "137": "看见更多语种，看见更多可能",
    "138": "用户反馈",
    "139": "王先生",
    "140": "从事LLM相关领域研发",
    "141": "非常好，推荐！",
    "142": "很不错，有了这个工具再也不用带着纸质资料外出了。",
    "143": "文本提取准确度很高，大多数类型的扫描件转换完都没什么误差。",
    "144": "转换完体验太棒了！终于可以翻译成多种语言！阅读毫无障碍！",
    "145": "看了别人推荐过来的，很好用。",
    "146": "PDF到PDF的转化",
    "147": "图片转文字",
    "148": "使用场景",
    "149": "常见问题",
    "150": "博客",
    "151": "在线客服",
    "152": "Q&A",
    "153": "Blog",
    "154": "阅读爱好者",
    "155": "分享更多Pdf to Pdf相关内容",
    "156": "分享更多Pdf to Pdf相关内容、ocr文本提取技术、文件瘦身",
    "157": "常见问题，图片OCR文本识别FAQ",
    "158": "在这里，我们列出了一些关于pdf到pdf转换的常见问题。如果您没有找到所需的信息，请随时通过pdftopdf@leqi.ai与我们联系。",
    "159": "免费在线OCR—将PDF扫描件转换为文本",
    "160": "通过ocr技术，将pdf扫描件转换为pdf文本文件，一键实现文件瘦身的同时保留文件原排版。阅读体验更佳，内容复制、搜索无阻碍!",
    "161": "上传文件，通过OCR技术快速识别pdf扫描件内容，提取结果精准度高达99%＋。PDF扫描件内容提取后，点击下载即可获得pdf文本格式文件。",
    "162": "Pdf to Pdf| 高效工具全方位定价方案 | 量身定制满足您的需求",
    "163": "探索Pdf to Pdf软件的灵活定价计划，体验无与伦比的功能，享受个性化服务，现在注册更有超值优惠！",
    "164": "更多Pdf文档ocr文本提取处理记录",
    "165": "点击查看历史处理记录。",
    "166": "验证码错误！",
    "167": "邮箱登录",
    "168": "请输入邮箱",
    "169": "请输入密码",
    "170": "验证码已发送至邮箱",
    "171": "请输入验证码",
    "172": "设置密码",
    "173": "从事商务工作",
    "174": "刘先生",
    "175": "从事教师工作",
    "176": "赵先生",
    "177": "陈先生",
    "178": "个人财务管理者",
    "179": "张女士",
    "180": "展开",
    "181": "可处理页书： ",
    "182": "语言",
    "183": "PDF格式转换工具主要特点",
    "184": "文字识别准确度高",
    "185": "高精度OCR文字识别技术，",
    "186": "高达99.5%",
    "187": "的识别精确度让扫描件即刻能用，",
    "188": "减少90%",
    "189": "无效时间。目前可识别中文简体、英文，后续还会新增日语、德语等其他语言。",
    "190": "批量处理高效快捷",
    "191": "支持一次性处理多张PDF扫描件，",
    "192": "在线批量",
    "193": "文字识别，一键批量格式转换，极大提高效率。",
    "194": "机器学习识别，准确度随着自动学习越来越高，识别速度快；识别经过加密处理，无需担心隐私。",
    "195": "图片转文字？不够！还要",
    "196": "保留格式",
    "197": "！PDF在线格式转换工具让你从繁琐的逐字输入文本的工作中解放，同时摆脱人工比对原稿逐字校对、排版。",
    "198": "压缩PDF扫描件",
    "199": "图片变文字",
    "200": "，立刻节省95%存储空间，经测试90MB文件处理后可缩小至6MB，在高清化的同时有效释放接近94%的空间，把钱花在刀刃上，PDFtoPDF",
    "201": "在线工具",
    "202": "高效为您解决问题！",
    "203": "为出行减负",
    "204": "摆脱纸质资料，随时随地打开手机即可阅读，给读者带来清晰愉悦的阅读体验。从PDF扫描件中提取文本，创建",
    "205": "可搜索、可复制和可访问",
    "206": "的内容，给创作者带来复制粘贴的快感！",
    "207": "OCR自动识别PDF扫描件，转换结果高清",
    "208": "只需将PDF扫描件上传，等待几秒，OCR将自动扫描并提取扫描件中的文字，一比一转换为更清晰的PDF文本文件，从而创建便于搜索、编辑和访问的内容。",
    "209": "支持移动端 & PC端",
    "210": "无论是手机上还是电脑上，都可以使用，直接通过网址进入，无需下载，方便快捷、不占内存！再也不用担心遇到临时有文件需要处理的情况啦，直接在手机移动端完成在线OCR识别，随时随地高效完成！",
    "211": "学生与老师",
    "212": "学生可以使用OCR技术将教材、笔记或研究资料中的文字快速提取出来，便于查找、整理学习材料或制作电子笔记。教师可以扫描讲义、参考书籍，通过OCR转换成文本，用于制作PPT、在线课程资料等。",
    "213": "做出海业务的企业",
    "214": "企业出海业务合同扫描件经OCR技术，一键转换为PDF文本文件，提升信息录入速度与准确性，便于后期翻译，让您快速了解合同内容，支持搜索查找重要条款，增强跨国合同管理的效率与便利性。",
    "215": "电子书企业使用OCR工具从扫描的文档、书籍、杂志等纸质材料中提取文字，快速实现纸质内容数字化，提升扫描件内容的可访问性、增强用户体验。",
    "216": "经常需要外出或习惯于用电子设备阅读的朋友，使用OCR在线工具将准备看的书扫描为电子文档，随时随地皆可阅读。还可以根据个人喜好调整电子文本布局、字体和颜色，营造个性化阅读环境。更重要的是彻底让我们摆脱传统厚重的纸质书本，最大程度节省空间、减轻负重。",
    "217": "OCR工具为语言学习者开启了一扇通往更广阔学习资源的大门，不仅能够将纸质材料瞬间转化为可编辑的电子文本，便于翻译、听写练习及个性化学习资料的创建，还可促进跨文化交流和学术研究的深化。",
    "218": "OCR技术为残障人士，尤其是视觉障碍者和阅读困难者，架起了通往信息自由和平等获取的桥梁。它将纸质材料瞬间转化为可听、可视的电子格式，满足多样化阅读需求，助力残障人士自主学习、高效沟通，极大地提升了他们在教育、工作和社会生活中的参与度与独立性。通过OCR，我们共同构建了一个更加包容和无障碍的世界。",
    "219": "如何将PDF扫描件转换为PDF文本？",
    "220": "上传PDF扫描件",
    "221": "OCR识别和提取",
    "222": "通过OCR技术",
    "223": "快速",
    "224": "识别pdf扫描件内容，提取结果精准度高达",
    "225": "99%＋",
    "226": "PDF扫描件内容提取后，点击下载即可获得pdf文本格式文件，便于查找内容、节省存储空间、阅读体验更佳",
    "227": "PDF扫描件内容提取后，点击下载即可获得pdf文本格式文件，便于查找内容、",
    "228": "节省存储空间、阅读体验更佳",
    "229": "邮箱：",
    "230": "立即转换 pdf",
    "231": "PDF扫描件文字提取利器，一键提升企业与学术文献处理效率",
    "232": "PDFtoPDF文字提取利器，可用于快速摘录文献、报纸、杂志上的重要信息，进行引用或创作，提高研究和写作效率。支持将手稿或旧文档数字化，方便存储和检索，是LLMs企业和学术研究机构的得力助手！",
    "233": "高效提取PDF扫描件文本——电子书企业必备工具",
    "234": "利用先进的OCR技术，PDFtoPDF网站能精准提取PDF扫描件中的文字内容，助力电子书企业快速将纸质书籍、文档转变为可编辑的文本，实现数字化飞跃。简化编辑流程，提升出版效率，为您的电子书项目注入无限创意与灵活性。立即体验，开启智慧出版新时代。",
    "235": "PDF扫描件图片文本一键提取：从图片到清晰文字，升级阅读爱好者体验",
    "236": "专为阅读爱好者设计，这款OCR工具能巧妙地从PDF扫描件中抓取图片内的文字，将其转化为清晰、易读的文本格式。从此，不再受限于扫描文档的阅读不便，享受如纸质书般的流畅与清晰，让每一次阅读都成为一种享受。提升文本质量，尽享无界阅读新乐趣，现在就加入我们，开启您的舒适阅读之旅。",
    "237": "教育助力：PDF扫描件文本轻松提取，优化学习资料，提升教学体验",
    "238": "专为学生和教师打造的OCR解决方案，高效从PDF扫描件及图片中精准提取文本，转化成清晰可编辑的内容，让繁琐的学习资料瞬间变得井井有条。无论是论文研读、讲义整理还是教材制作，都能显著提升效率，助力学术研究与课堂教学迈上新台阶。立即体验，开启智慧学习新篇章。",
    "239": "OCR文字识别技术：将PDF扫描件转化为语音可读文本，构筑无障碍信息桥梁",
    "240": "利用先进的Text Recognition OCR技术，将PDF扫描文档轻松转换成可编辑、可语音阅读的文本，打破了残障人士阅读障碍。无论是学习资料、工作文件还是日常资讯，都能瞬间转化，让信息获取不再受限，促进数字内容的全面无障碍。",
    "241": "OCR技术赋能语言学习：PDF扫描件秒变文本，多语言翻译随心阅",
    "242": "OCR文本提取 & 翻译神器：一键将PDF扫描文档转换成可编辑文本，无论是外语教材还是原版文献，都能即扫即译。支持多种语言切换，大大节省查阅时间，多语言阅读无障碍，学习加速新体验！",
    "243": "上传PDF扫描件，轻松转换文本——高效PDF转文字工具",
    "244": "端点",
    "245": "认证",
    "246": "需要'web'类型的JWT Token",
    "247": "请求头",
    "248": "响应",
    "249": "成功",
    "250": "失败",
    "251": "Python代码示例",
    "252": "使用示例",
    "253": "参数",
    "254": "需要'test_company'类型的JWT Token",
    "255": "PDF文件，作为multipart/form-data上传",
    "256": "错误信息，例如：No file part in the request",
    "257": "PDF处理任务的ID",
    "258": "API",
    "259": "正式密钥",
    "260": "测试环境",
    "261": "接入文档",
    "262": "申请发票",
    "263": "获取密钥",
    "264": "密钥:",
    "265": "复制",
    "266": "充值",
    "267": "剩余：",
    "268": "页",
    "269": "登录获取密钥",
    "270": "可免费测试3次PDF文件",
    "271": "1. 获取客户端密钥",
    "272": "复制代码",
    "273": "2. 获取访问令牌",
    "274": "3. 上传PDF文件",
    "275": "4. 获取PDF处理状态",
    "276": "5. 下载PDF文件",
    "277": "企业抬头:",
    "278": "请输入",
    "279": "税号:",
    "280": "开户行:",
    "281": "电话:",
    "282": "地址:",
    "283": "申请",
    "284": "10000页",
    "285": "50000页",
    "286": "复制成功",
    "287": "请确保将 `<YOUR_WEB_JWT_TOKEN>`, `<YOUR_TEST_COMPANY_JWT_TOKEN>`,",
    "288": "`<YOUR_CLIENT_SECRET>`, `<YOUR_TASK_ID>`, 和 `path/to/your/file.pdf` 替换为合适的值，以进行测试。",
    "289": "请完善所有信息",
    "290": "提交成功",
    "291": "感谢您的提交，我们将在10个工作日内处理您的开票请求，如有疑问或其他要求请联系我们的邮箱：pdftopdf@leqi.ai",
    "292": "收起",
    "293": "出于隐私保护，我们将最多为您保留30天内的文件",
    "294": "之后会进行删除，请尽快保存"
}